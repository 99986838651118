var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [
        _vm._v('Schade "' + _vm._s(_vm.damage.name) + '" dupliceren')
      ]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _vm._v(
                        "U gaat een schade dupliceren inclusief alle velden."
                      )
                    ]),
                    _vm.defaultVisibility.images
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "Schade inclusief foto's dupliceren",
                                primary: "",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.duplicateOptions.images,
                                callback: function($$v) {
                                  _vm.$set(_vm.duplicateOptions, "images", $$v)
                                },
                                expression: "duplicateOptions.images"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.defaultVisibility.repairs
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "Schade inclusief reparaties dupliceren",
                                primary: "",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.duplicateOptions.repairs,
                                callback: function($$v) {
                                  _vm.$set(_vm.duplicateOptions, "repairs", $$v)
                                },
                                expression: "duplicateOptions.repairs"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "mr-3 fadedText link",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.duplicate()
                              }
                            }
                          },
                          [_vm._v("Schade dupliceren")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }