import { Damage } from '@/models/Damage';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<DuplicateDamageDialog>({})
export default class DuplicateDamageDialog extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  @Prop()
  protected damage!: Damage;

  @Prop()
  protected visibility!: DuplicateOptions;
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected defaultVisibility: DuplicateOptions = {
    images: true,
    repairs: true,
  };

  protected duplicateOptions: DuplicateOptions = {
    images: false,
    repairs: false,
  };

  protected isLoading = false;
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.defaultVisibility = { ...this.defaultVisibility, ...this.visibility };
  }
  // #endregion

  // #region Class methods
  protected close() {
    this.$emit('input', false);
  }
  // #endregion

  // #region Async methods
  protected async duplicate(): Promise<void> {
    if (! this.damage) {
      return;
    }

    this.isLoading = true;
    const damage = await new Damage()
      .duplicateDamage(this.damage)
      .include(['validations', 'reject_reasons'])
      .create({
        media: this.duplicateOptions.images,
        repairs: this.duplicateOptions.repairs,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;
    this.close();

    this.$emit('damageDuplicated', damage);
  }
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}

interface DuplicateOptions {
  images: boolean;
  repairs: boolean;
}
